import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <Link to="/lessons" className="bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-700 transition">
                <h2 className="text-xl font-bold mb-4">إحصائية الدروس</h2>
                <p>عرض إجمالي الدروس وإحصائياتها الشهرية.</p>
            </Link>
            <Link to="/audio" className="bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-700 transition">
                <h2 className="text-xl font-bold mb-4">إحصائية الصوتيات</h2>
                <p>عرض إجمالي الصوتيات وإحصائياتها الشهرية.</p>
            </Link>
        </div>
    );
};

export default HomePage;
