import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './HomePage';
import LessonStatistics from './LessonStatistics';
import AudioStatistics from './AudioStatistics';

function App() {
    return (
        <div className="dark:bg-gray-900 dark:text-white min-h-screen">
            <Router>
                <div className="container mx-auto p-4">
                    <nav className="mb-8">
                        <h1 className="text-3xl font-bold text-center mb-6">إحصائيات التطبيق</h1>
                        <ul className="flex justify-center space-x-4">
                            <li><Link to="/" className="text-blue-400">الصفحة الرئيسية</Link></li>
                        </ul>
                    </nav>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/lessons" element={<LessonStatistics />} />
                        <Route path="/audio" element={<AudioStatistics />} />
                    </Routes>
                </div>
            </Router>
        </div>
    );
}

export default App;
