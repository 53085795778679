import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const LessonStatistics = () => {
    const [lessonData, setLessonData] = useState([]);

    const monthNames = {
        "January": "يناير",
        "February": "فبراير",
        "March": "مارس",
        "April": "أبريل",
        "May": "مايو",
        "June": "يونيو",
        "July": "يوليو",
        "August": "أغسطس",
        "September": "سبتمبر",
        "October": "أكتوبر",
        "November": "نوفمبر",
        "December": "ديسمبر"
    };

    useEffect(() => {
        axios.get('https://merath-alanbea.com/api/tuition/statistics')
            .then(response => setLessonData(response.data))
            .catch(error => console.error("Error fetching lesson data:", error));
    }, []);

    const chartData = {
        labels: lessonData.map(item => monthNames[item.month]),
        datasets: [
            {
                label: 'إجمالي الدروس',
                data: lessonData.map(item => item.total_lessons),
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
            },
            {
                label: 'إجمالي الملفات الصوتية',
                data: lessonData.map(item => item.total_sounds),
                backgroundColor: 'rgba(255, 159, 64, 0.6)',
            },
        ],
    };

    const chartOptions = {
        plugins: {
            legend: { display: true, position: 'top' },
            tooltip: { callbacks: { label: (context) => `العدد: ${context.raw}` } }
        },
        scales: {
            y: { beginAtZero: true, ticks: { stepSize: 500 } },
        },
    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold text-center mb-6">إحصائية الدروس</h2>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default LessonStatistics;
